@import "../variables";
@import "../../bootstrap/mixins";

.cta-hint {
	font-weight: 400;

	a {
		font-weight: 700;
	}
}

.img-custom {
	max-width: 100%;
}

.hero {
	.hero-breadcrumbs {
		padding-bottom: 0;
	}

	.hero-note {
		@extend .cta-hint;
	}

	.hero-awards-wrap {
		@include media-breakpoint-up(lg) {
			margin-top: $bu*2;
		}
	}
}

.features-section {
	padding: $bu*5 0 $bu*5;

	@include media-breakpoint-up(lg) {
		padding: $bu*5 0 $bu*7;
	}
}

.features-privacy-section, .features-security-section {
	.feature-item {
		border-color: $orange-pale;
		background-color: $orange-off-white;
	}
}

.media-secure-browser-pro {
	> .container {
		@include media-breakpoint-down(sm) {
			background-image: none !important;	// sorry for the !important. Inline style has to be overwritten
		}
	}
}

.product-info {
	.tabs-wrap {
		@include media-breakpoint-down(lg) {
			margin-top: $bu*2.5;
			margin-bottom: $bu*2.5;
		}
	}

	.product-info-faq > .row + .faq {
		@include media-breakpoint-down(lg) {
			margin-top: $bu*2;
		}
	}
}

.bft {
	.bft-hint {
		@extend .cta-hint;
	}
}

#modal-secureBrowserOopsMac {
	&.modal {
		.modal-body {
			justify-content: center;
			align-self: center;
		}
	}
}


/* Platforms differencies without JP */
.js-mac,
.js-ios,
.js-android {
	.stripe-wrap {
		background: linear-gradient($white 50%, $orange-faint 50%);
	}
}
.js-ios,
.js-android {
	.bft {
		&.section-py {
			padding-top: 0;
		}
	}
}


.js-ios,
.js-android {
	.management {
		padding-top: $bu*5;

		@include media-breakpoint-up(lg) {
			padding-top: $bu*7;
		}
	}
}


//
// Other locales - text differencies
//
.mod-de-de {
	.hero {
		.hero-text {
			br {
				@include media-breakpoint-down(xl) {
					display: inline;
				}
			}
		}
	}
}

.mod-no-no,
.mod-sv-se,
.mod-da-dk,
.mod-nl-nl,
.mod-nl-be {
	.management {
		h2 {
			@media (max-width: 420px) {
				word-break: break-all;
			}
		}
	}
}

// Hero headline - fix long words overlapping visual
.mod-ru-ru,
.mod-ru-ua,
.mod-ru-kz {
	.hero-headline {
		word-break: break-word;
	}
}


// USP feature stripe - fix long words
.mod-de-de,
.mod-fi-fi,
.mod-da-dk,
.mod-hu-hu,
.mod-ru-ru,
.mod-ru-ua,
.mod-ru-kz,
.mod-uk-ua {
	.stripe-wrap .feature-item-body {
		word-break: break-word;
	}
}

